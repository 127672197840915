<template>
  
    <v-card outlined style="padding: 40px 20px">
      <div class="d-flex justify-space-between">
        <h1 class="headline">Detailed Design</h1>

        <v-btn color="primary" @click="uploadDetailedDesignDialog = true" depressed class="mr-0"
        v-if="designDetails.canUploadDetailedDesign"
          >Upload</v-btn
        >
      </div>

        <div class="row">
      <div
        class="col-md-3"
        v-for="item in designDetails.detailedDesigns"
        :key="item.id"
      >
         <v-card outlined>
          <v-img
            :src="file_url + item.fileName"
            height="200"
          ></v-img>
          <v-card-subtitle>
            {{ formatDate(item.uploadedDate) }}
          </v-card-subtitle>

          <v-card-title class="text-capitalize">{{
            item.caption
          }}</v-card-title>
        </v-card>
      </div>
    </div>
    

    <v-dialog v-model="uploadDetailedDesignDialog" max-width="600">
      <v-card class="pa-5">
        <v-card-title class="text-h5"> Upload Detailed Design </v-card-title>

        <v-file-input
          truncate-length="15"
          @change="handleFileChange"
        ></v-file-input>

        <v-text-field label="Caption" v-model="caption"></v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="uploadDetailedDesignDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="uploadDetailedDesign">
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
  
</template>

<script>
import {bus} from "./../../../main";
export default {
  props: {
    designDetails: {
      required: true,
    },
  },

  data() {
    return {
      file_url:process.env.VUE_APP_FILE_URL+'/',
      uploadDetailedDesignDialog: false,
      currentFile: null,
      caption: "",
      fileName: "",
    };
  },
  methods: {
    handleFileChange(ev) {
      this.currentFile = ev;
    },
    async uploadDetailedDesign() {
      let formData = new FormData();
      formData.append("file", this.currentFile);

      let uploadFileResponse = await this.$FileApi.uploadFile(formData);
      let fileName = uploadFileResponse.data.data;

      let data = {
        id: this.$route.params.id,
        uploadFiles: [
          {
            fileName: fileName,
            caption: this.caption,
          },
        ],
      };

      let uploadDetailedDesignResponse = await this.$Design.uploadDetailedDesign(
        data
      );
      this.uploadDetailedDesignDialog = false;
      bus.$emit('refreshDesignList');
      

      // this.$Design.uploadDetailedDesign().then(data=>{
      //     console.log(data.data);
      // }).catch(error=>{
      //     console.log(error);
      // })
    },
  },

  mounted() {},
};
</script>


<style scoped>
</style>