<template>
  <v-container>
    <v-row>
      <v-col md="8">
    <v-card class="pa-5" outlined>
      <div class="headline">Title: {{ designDetails.title }}</div>
      <div class="body-1 mt-3">Inquiry Type: {{ designDetails.inquiryType }}</div>
      <div class="body-1">Area: {{ designDetails.area }}</div>
      <div class="body-1">Created Date:  {{formatDate(designDetails.createdDate, 'Do MMM YYYY hh:mm A')}}</div>
      <div class="body-1">Remarks: {{ designDetails.remarks }}</div>
      <div class="body-1">Status: {{ designDetails.status }}</div>
    </v-card>
     </v-col>
      <v-col md="4">
        <v-card  outlined
      class="pa-5 mx-auto">
      <h2 class="headline text-capitalize mb-2">
           Customer Details
          </h2>
            <div>
               Name: {{designDetails.customer.name}}
            </div>
            <div>
                Email: {{designDetails.customer.email}}
            </div>
            <div>
                Phone: {{designDetails.customer.phone}}
            </div>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="!loading">
   <Measurement :designDetails="designDetails" v-if="designDetails.canUploadMeasurement || designDetails.measurements.length>0"/>
    <SampleDesign :designDetails="designDetails" v-if="designDetails.canUploadSampleDesign || designDetails.sampleDesigns.length>0"/>
    <RenderView :designDetails="designDetails" v-if="designDetails.canUploadRenderView || designDetails.renderViews.length>0 "/>
    <DetailedDesign :designDetails="designDetails" v-if="designDetails.canUploadDetailedDesign || designDetails.detailedDesigns.length>0"/>
      </div>
  </v-container>
</template>


<script>
import Measurement from "./Measurement.vue";
import SampleDesign from "./SampleDesign.vue";
import RenderView from "./RenderView.vue";
import DetailedDesign from "./DetailedDesign.vue";
import {bus} from "./../../../main";

export default {
components:{
    Measurement,
SampleDesign,
RenderView,
DetailedDesign
},

  data() {
    return {
      id: this.$route.params.id,
      loading: true,
      designDetails: {},

      // measurement
    
      //   end of measurement
    };
  },
  methods: {
    getDesignById() {
      
      this.$Design
        .getDesignById(this.id)
        .then((data) => {
          this.designDetails = data.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
   
  },
  created(){
        bus.$on('refreshDesignList', () => {
      this.getDesignById();
    })
    },

  mounted() {
    this.getDesignById();
  },
};
</script>