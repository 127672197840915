<template>
  <v-card class="pa-5" outlined>
    <div class="d-flex justify-space-between">
      <h1 class="headline">Measurement</h1>
      <div>

         <v-dialog v-model="uploadMeasurementDialog" max-width="600">
      <v-card class="pa-5">
        <v-card-title class="text-h5"> Upload Measurement </v-card-title>

        <v-file-input
          truncate-length="15"
          @change="handleFileChange"
        ></v-file-input>

        <v-text-field label="Caption" v-model="caption"></v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="uploadMeasurementDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn class="primary"  text @click="uploadMeasurement">
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


        <v-dialog v-model="approveMeasurementDialog" max-width="600">
          <v-card>
            <v-toolbar color="primary" dark>Approve Measurement</v-toolbar>
            <v-card-text>
              <div class="text-h2">
                <v-text-field
                type="number"
                  label="Sample Design Price"
                  
                  v-model="sampleDesignPrice"
                ></v-text-field>

                <v-text-field
                type="number"
                  label="Render View Price"
                  
                  v-model="renderViewPrice"
                ></v-text-field>

                <v-text-field
                type="number"
                  label="Detailed Design Price"
                  
                  v-model="detailedDesignPrice"
                ></v-text-field>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              
              <v-btn text @click="approveMeasurementDialog = false"
                >Close</v-btn
              >
              <v-btn class="primary" text @click="approveMeasurement">Approve</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="rejectMeasurementDialog" max-width="600">
          <v-card>
            <v-toolbar color="primary" dark>Reject Measurement</v-toolbar>
            <v-card-text>
              <div class="text-h2">
                <v-text-field
                  label="Remarks"
                  hide-details="auto"
                  v-model="remarks"
                ></v-text-field>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
            
              <v-btn text @click="rejectMeasurementDialog = false">Close</v-btn>
                <v-btn class="warning" text @click="rejectMeasurement">Reject</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

          <v-btn
          color="primary"
          dark
          @click.stop="uploadMeasurementDialog = true"
          depressed
          class="mr-3"
          v-if="designDetails.canUploadMeasurement"
        >
          Upload Measurement
        </v-btn>

        <v-btn
          color="primary"
          dark
          @click.stop="approveMeasurementDialog = true"
          depressed
          class="mr-3"
          v-if="designDetails.canApproveDisapproveDesign"
        >
          Approve
        </v-btn>

        <v-btn
          color="red"
          dark
          @click.stop="rejectMeasurementDialog = true"
          depressed
          outlined
          v-if="designDetails.canApproveDisapproveDesign"
        >
          Reject
        </v-btn>
      </div>
    </div>

    <div>Uploaded Date : {{ formatDate(designDetails.measurementUploadDate) }}</div>

    <div class="row">
      <div
        class="col-md-3"
        v-for="item in designDetails.measurements"
        :key="item.id"
      >
        <v-card outlined>
          <v-img
            :src="file_url+ item.fileName"
            height="200"
          ></v-img>
          <v-card-subtitle>
            {{ formatDate(item.uploadedDate) }}
          </v-card-subtitle>

          <v-card-title class="text-capitalize">{{
            item.caption
          }}</v-card-title>
        </v-card>
      </div>
    </div>
  </v-card>
</template>


<script>


import {bus} from "./../../../main";
export default {
  props: {
    designDetails: {
      required: true,
    },
  },


  data() {
    return {
      file_url:process.env.VUE_APP_FILE_URL+'/',
      id: this.$route.params.id,
      currentFile: null,
      caption: "",
      fileName: "",

      uploadMeasurementDialog: false,
      approveMeasurementDialog: false,
      rejectMeasurementDialog: false,
      designId: null,
      sampleDesignPrice: null,
      renderViewPrice: null,
      detailedDesignPrice: null,
      remarks: null,
    };
  },
  methods: {
    async uploadMeasurement(){
       let formData = new FormData();
      formData.append("file", this.currentFile);

        try{
           let uploadFileResponse = await this.$FileApi.uploadFile(formData);
      let fileName = uploadFileResponse.data.data;

      let data = {
        id: this.$route.params.id,
        uploadFiles: [
          {
            fileName: fileName,
            caption: this.caption,
          },
        ],
      };
      let uploadMeasurementResponse = await this.$Design.uploadMeasurement(
        data
      );
        this.uploadMeasurementDialog = false;
        bus.$emit('refreshDesignList');

        }catch(error){
          console.log(error);
          this.$toastr.error("Something Went Wrong");
        }
     
     
    },

    handleFileChange(ev) {
      this.currentFile = ev;
    },


    approveMeasurement(val) {
      console.log(val);
      let data = {
        designId: this.id,
        sampleDesignPrice: this.sampleDesignPrice,
        renderViewPrice: this.renderViewPrice,
        detailedDesignPrice: this.detailedDesignPrice,
      };
      this.$Design
        .approveDesign(data)
        .then((data) => {
          console.log(data.data.data);
          this.$toastr.success("Measurement Approved");
          this.approveMeasurementDialog = false;
          bus.$emit('refreshDesignList');
        })
        .catch((error) => {
          if (error.response) {
            this.$toastr.error(error.response.data.message);
          } else {
            this.$toastr.error("Something Went Wrong");
          }
          console.log(error);
        });
    },
    rejectMeasurement() {
      let rejectData = {
        designId: this.id,
        remarks: this.remarks,
      };
      this.$Design
        .disapproveDesign(rejectData)
        .then((data) => {
          console.log(data.data.data);
          this.rejectMeasurementDialog = false;
          this.$toastr.success("Measurement Rejected");
          bus.$emit('refreshDesignList');
        })
        .catch((error) => {
          if (error.response) {
            this.$toastr.error(error.response.data.message);
          } else {
            this.$toastr("Something Went Wrong");
          }
          console.log(error);
        });
    },
  },
};
</script>