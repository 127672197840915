<template>
  
    <v-card  outlined style="padding: 40px 20px">
      <div class="d-flex justify-space-between">
        <h1 class="headline">Render View</h1>

        <v-btn color="primary" @click="uploadRenderViewDialog = true" class="mr-0"
        v-if="designDetails.canUploadRenderView"
          >Upload</v-btn
        >
      </div>

        <div class="row">
      <div
        class="col-md-3"
        v-for="item in designDetails.renderViews"
        :key="item.id"
      >
        <v-card outlined>
           <v-img
            :src="file_url + item.fileName"
            height="200"
          ></v-img>
          <div>{{ formatDate(item.uploadedDate) }}</div>

          <h2>
            {{ item.caption }}
          </h2>
        </v-card>
      </div>
    </div>
    

    <v-dialog v-model="uploadRenderViewDialog" max-width="600">
      <v-card class="pa-5">
        <v-card-title class="text-h5"> Upload Render View </v-card-title>

        <v-file-input
          truncate-length="15"
          @change="handleFileChange"
        ></v-file-input>

        <v-text-field label="Caption" v-model="caption"></v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="uploadRenderViewDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="uploadRenderView">
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
  
</template>

<script>
import {bus} from "./../../../main";
export default {
  props: {
    designDetails: {
      required: true,
    },
  },

  data() {
    return {
      file_url:process.env.VUE_APP_FILE_URL+'/',
      uploadRenderViewDialog: false,
      currentFile: null,
      caption: "",
      fileName: "",
    };
  },
  methods: {
    handleFileChange(ev) {
      this.currentFile = ev;
    },
    async uploadRenderView() {
      let formData = new FormData();
      formData.append("file", this.currentFile);

      let uploadFileResponse = await this.$FileApi.uploadFile(formData);
      let fileName = uploadFileResponse.data.data;

      let data = {
        id: this.$route.params.id,
        uploadFiles: [
          {
            fileName: fileName,
            caption: this.caption,
          },
        ],
      };

      let uploadRenderViewResponse = await this.$Design.uploadRenderView(
        data
      );
      this.uploadRenderViewDialog = false;
      bus.$emit('refreshDesignList');

      // this.$Design.uploadRenderView().then(data=>{
      //     console.log(data.data);
      // }).catch(error=>{
      //     console.log(error);
      // })
    },
  },

  mounted() {},
};
</script>


<style scoped>
</style>